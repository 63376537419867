@import 'constants/styles.constants.module';

.searchBar {
  display: flex;

  @include max(xxs) {
    flex-wrap: wrap;
  }
}

div.input {
  flex-direction: row-reverse;
  height: 3.75rem !important;
  background-color: $colorWhite;
  border: unset;
  border-radius: 2rem;
  box-shadow: $boxShadowInput;
  @include ie-only() {
    box-shadow: $boxShadowInputIE;
  }

  input {
    margin: 0 !important;
    padding-right: 7rem;
    padding-left: 1.5rem;
    font-size: $fontSizeBiggerText;
    border: unset !important;
    border-radius: 2rem;

    svg {
      stroke: $colorMain;
      stroke-width: 2px;
    }
  }
}

.clearSearch {
  height: 2rem;
}

.clearSearchButton {
  color: $colorSecondary;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.iconWrap {
  position: absolute;
  right: 5rem;
  height: 1rem;

  @include ie-only() {
    top: 40%;
  }
}

svg.cancelIcon {
  cursor: pointer;
  stroke: $colorMain;
  @include ie-only() {
    width: 1rem;
  }

  &:hover * {
    stroke: $colorSecondary;
  }
}

.inputWrap {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 2rem;
}

.icon {
  height: 1.5rem;
  padding-left: 2rem;

  svg {
    stroke: $colorMain;
    stroke-width: 2px;
    @include ie-only() {
      width: 2rem;
    }
  }
}

.modalView {
  .searchBar {
    margin-top: 0.5rem;

    div.input {
      height: 2.5rem !important;
      border: 0.1rem solid $colorBorder;
      box-shadow: none;

      /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
      input {
        font-size: $fontSizeText;
      }

      > div {
        width: 2.5rem !important;
        height: 2.5rem !important;
      }
    }

    button {
      height: 2.5rem;
      border-radius: 1.5rem;
    }
  }
}
