@import 'constants/styles.constants.module';

div.root {
  @include back-drop($colorMain);
  background-color: $colorMain;

  > div {
    padding: 1.3rem 2rem;
    width: 100%;
  }
}

.flexSpaceBetween {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-flow: wrap;
}

.subFieldsWrapper {
  align-items: flex-end;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  min-height: 43px;
  text-align: center;

  @include max(m) {
    border-left: unset;
    flex-basis: unset !important;
    padding-right: 0.8rem !important;
  }

  @include max(xxs) {
    flex-wrap: wrap;
  }
}

.lineAfterFirst {
  &:nth-child(-n + 1) {
    border-bottom: 1px solid $colorBorderLine;
  }
}

.lineAfterThree {
  @include max(m) {
    border-bottom: unset !important;
  }

  &:nth-child(-n + 3) {
    border-bottom: 1px solid $colorBorderLine;
  }
}

.lineAfterFour {
  @include max(m) {
    border-bottom: unset !important;
  }

  &:nth-child(-n + 4) {
    border-bottom: 1px solid $colorBorderLine;
  }
}

.lineAfterFive {
  @include max(m) {
    border-bottom: unset !important;
  }

  &:nth-child(-n + 5) {
    border-bottom: 1px solid $colorBorderLine;
  }
}

.sizeP20 {
  width: calc(20% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP30 {
  width: calc(30% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP40 {
  width: calc(40% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP50 {
  width: calc(50% - 0.5rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP60 {
  width: calc(60% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP70 {
  width: calc(70% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP70 {
  width: calc(70% - 1rem);

  @include max(xs) {
    width: 100%;
  }
}

.sizeP100 {
  width: 100%;
}

div.bcgOrange {
  background-color: $colorBackgroundOrange;
}

div.bcgRed {
  background-color: $colorBackgroundRed;
}

div.bcgGreen {
  background-color: $colorBackgroundGreen;
}

div.bcgGray {
  background-color: $colorBackgroundGray;
}

div.noBcg {
  background-color: unset;
  @include ie-only() {
    background-color: transparent;
  }
}

div.smallMarginTop {
  margin-top: 1rem;
}

.spaceBetween {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flexStart {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flexRowCenter {
  align-items: center;
  display: flex;
  margin-top: 3rem;
}

.link {
  color: var(--colorSecondary);
  text-decoration: none;

  &:hover {
    color: var(--colorLinkHover);
  }
}

.formGroup {
  input,
  select {
    border: 1px solid #26336055;
    border-radius: 0.5rem;
    color: $colorMain;
    font-weight: $fontWeightLight;
    @include ie-only() {
      border: 1px solid rgba(38, 51, 96, 0.33);
    }
  }
}

.downgraded {
  backdrop-filter: none !important;
}

.half {
  width: 50%;
}

.center {
  display: flex;
  justify-content: center;
}
