@import 'constants/styles.constants.module';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.withBorder {
  height: 4rem;
  border: 1px solid $colorBorder;
  border-radius: 0.5rem;

  &.highlighted {
    border-color: $colorMain;
  }
}

.withBottomBorder {
  border-bottom: 1px solid $colorBorder;
}

.input {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  height: 2rem;
  padding-left: 1rem;
  color: var(--colorSecondary);
  font-weight: $fontWeightMedium;
  font-size: $fontSizeText;
  text-align: left;
  background: $colorWhite;
  border: unset;
  border-radius: 1rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $colorMain;
    font-weight: $fontWeightLight;
  }
}

.inputWithIcon {
  padding-right: 2rem;
}

.icon {
  height: 1.125rem;
  margin-right: 0.625rem;
}

.inputIcon {
  position: absolute;
  right: 4px;
  z-index: 3;
  display: flex;
  flex-direction: column;

  @include ie-only() {
    top: 0.55rem;
    right: 3px;
    width: 2rem;
  }

  svg {
    display: block;
    height: 5px;
    stroke: var(--colorSecondary);
  }

  div {
    height: 5px;
    margin-top: 2px;
    padding-right: 9px;
    padding-left: 9px;
  }

  div:hover {
    cursor: pointer;
  }

  div:first-child {
    margin-top: 0;
    margin-bottom: 2px;
  }

  /* stylelint-disable selector-max-compound-selectors */
  div:first-child svg {
    transform: rotate(180deg);
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.input[type='date'] {
  z-index: 2;
  background: $colorWhite;
}

.input::-webkit-calendar-picker-indicator {
  margin: 0;
  background-color: $colorWhite;

  &::after {
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #c9c;
  }
}

.whiteBackground {
  border-bottom: unset;
  border-radius: 1rem;
  box-shadow: $boxShadowInput;
  @include ie-only() {
    box-shadow: $boxShadowInputIE;
  }
}

.bigger {
  height: 4rem;
  font-size: $fontSizeBiggerText;
}

.error {
  color: $colorDarkHighlight !important;
  font-size: $fontSizeSmallestText;
}

.calendarIcon {
  position: absolute;
  right: 0.5rem;
  z-index: 1;
  width: auto;
  height: 1rem;
  @include ie-only() {
    position: relative;
    /* stylelint-disable max-nesting-depth */
    svg {
      width: 2rem;
    }
  }
}

.datepicker {
  position: relative;
  width: 100%;
}

.wrapper {
  @include ie-only() {
    input,
    select {
      border: inherit;
    }

    input::-ms-clear,
    select::-ms-clear {
      display: none;
    }

    input[type='date'] {
      width: 5rem;
    }
  }
}
