@import 'constants/styles.constants.module';

.root {
  align-items: center;
  border: unset !important;
  display: flex;
  height: 2rem;
}

.switch {
  display: inline-block;
  height: 2rem;
  margin-right: 1rem;
  position: relative;
  width: 4rem;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider::before {
  background-color: $colorWhite;
  bottom: 0.1rem;
  content: '';
  height: 1.8rem;
  left: 0.2rem;
  position: absolute;
  transition: 0.4s;
  width: 1.8rem;
}

input[value='true'] + .slider {
  background-color: var(--colorSecondary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--colorSecondary);
}

input[value='true'] + .slider::before {
  transform: translateX(1.8rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 3rem;
}

.slider.round::before {
  border-radius: 50%;
}

.disabled {
  cursor: default !important;

  input + .slider {
    background-color: $colorDisabled;
    border-color: $colorDisabled;
    pointer-events: none;
  }
}

.bigger {
  font-size: $fontSizeBiggerText;
  height: 4rem;
}

.checkboxLabel {
  color: $colorMain;
  cursor: pointer;
  font-size: 1rem;
  font-weight: $fontWeightLight;
  max-width: 50rem;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}
