@import 'constants/styles.constants.module';

.root {
  border: unset !important;
  @include max(xs) {
    width: 100%;
  }
}

.wrapper {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  @include max(xs) {
    display: block;
    flex: unset;
  }

  &.bigger {
    .radio {
      height: 4rem;
    }
  }

  input {
    display: none;
  }

  &.disabled {
    background-color: $colorDisabled;
    border-color: $colorDisabled;
    color: $colorWhite;
    pointer-events: none;
  }

  .radio {
    align-items: center;
    appearance: none;
    border: 1px solid $colorMainAlpha55;
    color: $colorMain;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-weight: $fontWeightNormal;
    justify-content: center;
    min-height: 2rem;
    outline: none;
    padding: 0 1rem;

    @include max(xs) {
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      width: 100%;
    }

    &:nth-child(2) {
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    &:last-child {
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    &:not(.active):hover {
      background-color: var(--colorSecondaryHover);
      color: $colorWhite;
    }

    &.active {
      background-color: var(--colorSecondary);
      color: $colorWhite;
    }
  }
}

.wrapper-auto {
  width: auto;
}
