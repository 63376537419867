@import 'constants/styles.constants.module';

.root {
  display: flex;
  align-items: center;
  height: 4rem;
  margin-top: 0.5rem;
}

.labelWrap {
  span {
    font-size: $fontSizeBiggerText;
  }
}
