@import 'constants/styles.constants.module';

.clearFilter {
  display: flex;
  width: 7.5rem;
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeSmallestText;
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    color: var(--colorSecondary);

    .cancelIcon * {
      stroke-width: 4px;
    }
  }
}

.iconWrap {
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  transition: 0.25s;
}

svg.cancelIcon {
  * {
    transition: 0.25s;
    stroke: $colorWarning;
    stroke-width: 3px;
  }
}
