@import 'constants/styles.constants.module';

.wrapper {
  @include ie-only() {
    input,
    select {
      border: inherit;
    }

    input::-ms-clear,
    select::-ms-clear {
      display: none;
    }

    input[type='date'] {
      width: 5rem;
    }
  }

  textarea:disabled {
    background-color: $colorInputDisabled;
    pointer-events: none;
  }
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.withBottomBorder {
  border-bottom: 1px solid $colorBorder;
}

.input {
  flex-grow: 1;
  max-width: 100%;
  min-height: 2rem;
  padding: 0.5rem 1rem;
  color: $colorSecondary;
  font-weight: $fontWeightMedium;
  font-size: $fontSizeText;
  text-align: left;
  background: $colorWhite;
  border: unset;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $colorMain;
    font-weight: $fontWeightLight;
  }
}

.icon {
  height: 1.125rem;
  margin-right: 0.625rem;
}

.whiteBackground {
  overflow: hidden;
  background-color: $colorWhite;
  border-bottom: unset;
  border-radius: 1rem;
  box-shadow: $boxShadowInput;
  @include ie-only() {
    box-shadow: $boxShadowInputIE;
  }
}

.bigger {
  min-height: 4rem;
  font-size: $fontSizeBiggerText;
}

.withBorder {
  min-height: 4rem;
  border: 1px solid $colorBorder;
  border-radius: 0.5rem;

  &.highlighted {
    border-color: $colorMain;
  }
}

.error {
  position: absolute;
  top: 105%;
  right: 0.3rem;
  color: $colorDarkHighlight !important;
  font-size: $fontSizeSmallestText;
}

.height-H10 {
  height: 10rem;
}

.height-H20 {
  height: 20rem;
}

.height-H30 {
  height: 30rem;
}
