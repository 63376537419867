@import 'constants/styles.constants.module';

.filter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-right: 1rem;

  &.checkbox {
    flex-direction: row-reverse;
    font-weight: $fontWeightLight;
    margin-right: 2rem;
  }
}

.checkboxWrapper {
  flex-direction: row-reverse;
  justify-content: flex-start;

  &:not(.columnOrder) {
    font-weight: 300;
    justify-content: flex-end;

    .input {
      flex-grow: unset;
    }
  }

  .filterLabel:not(.labelSize-TOP) {
    flex-basis: unset;
    margin-bottom: 0;
    margin-top: 0.35rem;

    @include ie-only() {
      flex-grow: 1;
    }

    @include max(xs) {
      width: 80%;
    }
  }

  .input {
    align-items: flex-end;
    display: flex;
    width: unset;
    @include ie-only() {
      flex-grow: 0;
    }
  }
}

.modalView {
  border: 0.1rem solid #d1e0f2;
  border-radius: 1rem;
  box-shadow: none;
}

.columnOrder {
  align-items: flex-start;
  flex-direction: column;
}

.filterLabel {
  display: block;
  flex-basis: 4rem;
  flex-shrink: 0;

  &.auto {
    flex-basis: auto;
  }
}

.input {
  flex-grow: 1;
  width: 2rem;

  &.checkbox:not(.widthFull) {
    margin-right: 0.5rem;
    width: auto;
  }
}

.size-S10 {
  width: 10rem;
  @include max(sm) {
    width: 100%;
  }
}

.size-S15 {
  width: 15rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S12 {
  width: 12.5rem;
  @include max(sm) {
    width: 100%;
  }
}

.size-S18 {
  width: 18rem;
  @include max(sm) {
    width: 100%;
  }
}

.size-S19 {
  width: 19rem;
  @include max(sm) {
    width: 100%;
  }
}

.size-S20 {
  width: 20rem;


  @include max(sm) {
    width: 100%;
  }

  @include max(xs) {
    width: auto;
  }
}

.size-S25 {
  width: 25rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S30 {
  width: 30rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S36 {
  width: 36rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S38 {
  width: 38rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S40 {
  width: 40rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-S50 {
  width: 50rem;
  @include max(sm) {
    width: 100%;
  }

  @include max(md) {
    width: 20rem;
  }
}

.size-BY_VALUE {
  width: auto;
}

.size-FULL,
.size-HALF,
.size-THIRD,
.size-FOURTH {
  width: 100%;
}

.labelSize-S {
  flex-basis: 2rem;
}

.labelSize-M {
  flex-basis: 4rem;
}

.labelSize-ML {
  flex-basis: 6rem;
}

.labelSize-L {
  flex-basis: 8rem;
}

.labelSize-XL {
  flex-basis: 15rem;
}

.labelSize-TOP {
  flex-basis: unset;
  min-height: 1.4rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.isEditForm.labelSize-TOP {
  padding-bottom: 0;
  padding-left: 0;
}

.widthFull {
  width: 100%;
}

.labelWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 50rem;

  .labelTooltip {
    height: 1rem;
  }
}

.tooltipIcon {
  color: $colorLightBlue;
  cursor: help;
  fill: $colorBlack;
  height: 1rem;
  stroke: $colorBlack;
  width: 1.5rem;
}

.removeIcon {
  background-color: $colorRed;
  border-radius: 50%;
  height: 1.25rem;
  margin-left: 0.5rem;
  padding: 0.25rem;
  stroke: $colorWhite;
}

.contentWrapper {
  position: relative;
}

.pointer {
  cursor: pointer;
}
