@use 'sass:math';

%navigation-chevron {
  position: absolute;
  top: 6px;
  display: block;
  width: 9px;
  height: 9px;
  border-color: $datepicker__muted-color;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';

  &--disabled,
  &--disabled:hover {
    border-color: $datepicker__navigation-disabled-color;
    cursor: default;
  }
}

%triangle-arrow {
  position: absolute;
  width: 0;
  margin-left: math.div(-$datepicker__triangle-size, 2);

  &::before,
  &::after {
    position: absolute;
    left: -$datepicker__triangle-size;
    z-index: -1;
    box-sizing: content-box;
    width: 1px;
    height: 0;
    border: $datepicker__triangle-size solid transparent;
    border-width: $datepicker__triangle-size;
    content: '';
  }

  &::before {
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-top: unset;
    border-bottom-color: $datepicker__background-color;
  }

  &::after {
    top: 0;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-top-color: #fff;
    border-bottom: unset;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}
