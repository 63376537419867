@import 'constants/styles.constants.module';

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.sortWrapper {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
}

.joiner {
  margin: 0 5px;
  font-size: 14px;
  cursor: default;
}

.inputIcon {
  display: flex;
  align-items: center;
  margin-right: 5px;
  user-select: none;

  @include ie-only() {
    top: 0.55rem;
    right: 3px;
    width: 2rem;
  }

  .icon {
    width: max-content;
    height: 1rem;

    svg {
      display: inline-flex;
      width: auto;
      height: 0.9rem;
      fill: var(--colorSecondary);
    }

    /* stylelint-disable max-nesting-depth */
    &:hover {
      svg {
        fill: var(--colorSecondaryHover);
      }
    }
    /* stylelint-enable max-nesting-depth */
  }
}

.sortActive {
  svg {
    stroke: $colorBackgroundGray;
  }
}

.centerText {
  justify-content: center;
}

.round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1rem;
  height: 1.1rem;
  margin-left: 0.3rem;
  color: $colorWhite;
  font-weight: $fontWeightLight;
  font-size: $fontSizeSmallText;
  background-color: rgba($colorMain, 0.5);
  border-radius: 2rem;
}
