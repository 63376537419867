@import 'constants/styles.constants.module';

%boxcolorshard {
  label {
    color: $colorGray;
  }

  div {
    color: $colorMain;
  }
}

.root {
  position: relative;
  margin-top: 1rem;
  background-color: $colorWhite;
  border-radius: 0.5rem;
  box-shadow: $boxShadowTable;

  &.withPadding {
    padding: 1.3rem 2rem;
  }

  &.noBackground {
    background-color: transparent;
  }

  .noPadding {
    padding: 0;
  }

  &.lightBcg {
    @extend %boxcolorshard;
    background-color: $colorWhite;
  }

  &.archivedBcg {
    @extend %boxcolorshard;
    @include back-drop($colorArchive);
    background-color: $colorArchive;

    a {
      color: $colorWhite;
      text-decoration: underline;
    }
  }

  &.transparentBcg {
    @extend %boxcolorshard;
    background-color: transparent;
    box-shadow: none;
    backdrop-filter: none;
  }

  &.darkBcg a {
    @extend %boxcolorshard;
    color: $colorWhite;
    text-decoration: underline;

    &:hover {
      color: var(--colorLinkHover);
    }
  }

  &.blueBcg {
    @extend %boxcolorshard;
    background-color: $colorBorder;
  }

  &.redDarkBcg {
    background-color: #582e32;
  }

  &.orangeBcg {
    @extend %boxcolorshard;
    background-color: $colorBackgroundOrange;
  }

  &.redBcg {
    @extend %boxcolorshard;
    background-color: $colorBackgroundRed;
  }

  &.greenBcg {
    @extend %boxcolorshard;
    background-color: $colorBackgroundGreen;
  }

  &.greyBcg {
    background-color: $colorBackgroundGray;
  }
}

.withUnsetAction {
  align-items: center;
  justify-content: center;
}

.withTitle {
  margin-top: 4.687rem;
}

.whiteFont {
  color: $colorWhite;
}

.cardAction {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-weight: $fontWeightSemiBold;
  cursor: pointer;
}

.iconPlusWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  padding: 0.25rem;
  background-color: $colorGreen;
  border-radius: 50%;
}

.actionHeader {
  width: 100%;
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeTitle2;
}

.blockHeader {
  position: absolute;
  top: -3.26rem;
  left: 0;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;

  .resetPosition {
    position: relative;
    top: auto;
  }
}

.onlyActions {
  top: -2.26rem !important;
}
