@import 'constants/styles.constants.module';

.root {
  display: flex;
  font-size: $fontSizeBiggestText;
  font-weight: $fontWeightLight;
  margin-top: 2.5rem;
  word-break: break-all;

  @include max(xs) {
    font-size: $fontSizeText;

    div {
      padding-left: 2.5rem;
    }

    div::before {
      left: 1rem;
      top: 0.6rem;
    }
  }

  div {
    padding-left: 5rem;
    position: relative;
  }

  div:first-child {
    padding-left: 0;
    position: relative;
  }

  div:first-child::before {
    display: none;
  }

  div::before {
    border-bottom: 1px solid;
    border-color: $colorMain;
    border-right: 1px solid;
    content: ' ';
    display: block;
    height: 0.5rem;
    left: 2.25rem;
    margin-right: 1rem;
    margin-top: -0.2rem;
    position: absolute;
    top: 0.9rem;
    transform: rotate(-45deg);
    width: 0.5rem;
  }
}

.mobileStepper {
  display: none;

  @include max(xs) {
    display: block;
    width: 100%;
  }
}

.breadCrumb {
  @include max(xs) {
    display: none;
  }
}

.activeBreadCrumb {
  color: var(--colorSecondary);
  font-weight: $fontWeightSemiBold;
}


.clickableBreadCrumb {
  &:hover,
  &:focus {
    color: var(--colorLinkHover);
    cursor: pointer;
  }
}
