@import 'constants/styles.constants.module';

.msl-flx {
  display: inline-flex;
  align-items: center;
}

.msl-vars {
  --menu-max-height: 15rem;
  --font-size: $fontSizeText;
  --border-radius: 1rem;
  --active-menu-shadow: $boxShadowInput;
  --line-height: 1;
  --active-menu-background: #fff;
  --active-menu-radius: var(--border-radius);
}

.msl-wrp * {
  line-height: var(--line-height);
}

.msl-wrp {
  position: relative;
  min-height: 2rem;
  margin-bottom: -0.5rem;
  padding-left: 1rem;
  background-color: $colorWhite;
  border-radius: var(--border-radius);
  outline: none;
  box-shadow: $boxShadowInput;

  &.bigger {
    min-height: 4rem;
    font-size: 1.25rem;
  }
}

.msl-open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.msl-disabled {
  pointer-events: none !important;
}

.msl-disabled > .msl {
  background-color: #f6f6f6;
}

.msl {
  display: flex;
  align-items: center;
  min-height: 2rem;
  overflow: hidden;
  border-radius: var(--border-radius);
  outline: none;
  cursor: text;
  transition: border-radius 0.2s;

  &.bigger {
    min-height: 4rem;
  }
}

.msl:not(.msl-active, .msl-active-up):hover {
  border-color: #505050;
}

.msl-active {
  z-index: 9999;
  background: var(--active-menu-background);
  border-color: transparent;
  border-bottom: 0;
  border-radius: var(--active-menu-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: var(--active-menu-shadow);
  transition: border-top-left-radius 0.4s, border-top-right-radius 0.4s;
}

.msl-active-up {
  border-color: transparent;
  transition: border-bottom-left-radius 0.4s, border-bottom-right-radius 0.4s;
}

.msl-active-up,
.msl-active ~ .msl-options {
  z-index: 9999;
  margin-left: -1rem;
  background: var(--active-menu-background);
  border-radius: var(--active-menu-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: var(--active-menu-shadow);
}

.msl-active ~ .msl-options {
  max-height: var(--menu-max-height);
  transition: border-bottom-left-radius 0.5s ease-in,
    border-bottom-right-radius 0.5s ease-in;
  clip-path: inset(0 -10px -10px -10px);
  clip-path: inset(0 -10px -10px -10px);
}

.msl-active-up ~ .msl-options {
  bottom: 99%;
  z-index: 9999;
  max-height: var(--menu-max-height);
  background: var(--active-menu-background);
  border-bottom: 0;
  border-radius: var(--active-menu-radius);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: var(--active-menu-shadow);
  transition: border-top-left-radius 0.7s, border-top-right-radius 0.7s;
  clip-path: inset(-10px -10px 0 -10px);
  clip-path: inset(-10px -10px 0 -10px);
}

.msl-options {
  position: absolute;
  width: 100%;
  max-height: 0;
  overflow: auto;
  background: #fff;
  border-radius: var(--border-radius);
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  scrollbar-width: thin;
}

.biggerDropdown {
  width: calc(100% + 2px);
  margin-top: -5px;
  margin-left: calc(-1rem + -1px) !important;
  border: 1px solid $colorMainAlpha55;
  border-top: unset;
}

.msl-input,
.msl-input-wrp {
  display: inline-block;
  vertical-align: top;
}

.msl-input {
  margin: 5px;
  padding: 3px;
  font-size: var(--font-size);
  outline: none;
}

.msl-input::before {
  color: #929292;
  line-height: var(--line-height);
}

.msl-input[data-placeholder]:not([data-placeholder='']):empty::before {
  content: attr(data-placeholder);
}

.msl-chip {
  display: inline-flex;
  align-items: center;
  padding: 1px 3px 1px 6px;
  white-space: nowrap;
  white-space: break-spaces;
  background: #eee;
  border-radius: 5px;
}

.msl-chip,
.msl-single-value {
  margin: 5px;
  font-size: var(--font-size);
}

.msl-single-value {
  display: inline-block;
  padding: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: top;
  line-break: strict;
}

.msl-options::-webkit-scrollbar {
  width: 8px;
}

.msl-options::-webkit-scrollbar-track {
  background: transparent;
}

.msl-options::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.msl-options::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.msl-grp-title,
.msl-options .msl-option {
  margin: 4px 6px;
  padding: 0.25rem 1rem;
  font-size: var(--font-size);
  line-height: var(--line-height);
  white-space: break-spaces;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: background 0.2s;
  line-break: anywhere;
}

.msl-btn:focus,
.msl-btn:focus-visible {
  outline: none;
}

.msl-option:focus,
.msl-option:focus-visible,
.msl-wrp:focus,
.msl-wrp:focus-visible {
  outline: none;
}

.msl-grp-title {
  font-size: calc(var(--font-size) - 2px);
  background: #fafafa;
  cursor: default;
  opacity: 0.7;
}

.msl-options .msl-option:last-child {
  margin-bottom: 5px;
}

.msl-option:focus,
.msl-options .msl-option:hover {
  color: $colorWhite;
  background: var(--colorSecondary);
}

.msl-option-active {
  color: #0351ff;
  background: #e6f4fa;
}

.msl-option-disable {
  color: rgba(0, 0, 0, 0.3);
}

.msl-btn {
  justify-content: center;
  padding: 0;
  background: transparent;
  border: unset;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.msl-actions {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translatey(-50%);
}

.msl-arrow-btn,
.msl-clear-btn {
  width: 25px;
  height: 25px;
}

.msl-arrow-btn:hover,
.msl-clear-btn:hover {
  background: #d3d3d3;
}

.msl-arrow-icn > line,
.msl-close-icn > line {
  stroke-width: 1;
}

.msl-arrow-icn,
.msl-chip-delete {
  width: 20px;
  height: 20px;
}

.msl-chip-delete {
  min-width: 20px;
  min-height: 20px;
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.6);
}

.msl-chip-delete:hover {
  background: #d3d3d3;
}

.msl-chip-delete .msl-close-icn > line {
  stroke-width: 1;
}

.msl-close-icn {
  width: 10px;
  height: 10px;
}
