@import 'constants/styles.constants.module';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.wrapper {
  position: relative;
  width: 100%;

  select:disabled {
    background-color: $colorInputDisabled;
  }

  select:focus {
    outline: none;
  }

  select option:hover {
    color: $colorWhite;
    background-color: var(--colorSecondary);
  }
}

.list {
  width: 100%;
  height: 2rem;
  padding-right: 2rem;
  padding-left: 1rem;
  color: $colorMain;
  font-weight: $fontWeightLight;
  font-size: $fontSizeText;
  text-overflow: ellipsis;
  background: $colorWhite;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 13px);
  background-position-y: 0.8rem;
  background-size: 0.6rem;
  border: unset;
  border-radius: 1rem;
  -webkit-appearance: none;
  appearance: none;
}

.list::-ms-expand {
  display: none;
}

.bluePlaceholder {
  color: var(--colorSecondary);
  font-weight: $fontWeightMedium;
}

.bigger {
  height: 4rem;
  font-size: $fontSizeBiggerText;
  background-position-y: 1.86rem;
}

.multiple {
  height: 4rem;
}

.error {
  position: absolute;
  top: 105%;
  right: 0.3rem;
  color: $colorDarkHighlight !important;
  font-size: $fontSizeSmallestText;
}
