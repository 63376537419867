@import 'constants/styles.constants.module';

div.root {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  div {
    input {
      border: 0.1rem solid $colorBorderLine !important;
      border-radius: 0.4rem !important;
    }
  }

  div.wrapper {
    flex-basis: 4rem;

    .actions {
      display: flex;
      height: 1rem;
      margin-left: 0.2rem;
    }

    .tooltipWrapper {
      width: 1rem;
    }

    svg {
      width: 1rem;
      stroke-width: 2px;

      /* stylelint-disable max-nesting-depth */
      &:nth-child(2) {
        margin-left: 0.3rem;
      }

      &.cancel {
        fill: $colorRed;
        stroke: $colorRed;
      }

      &.success {
        fill: $colorSuccess;
        stroke: $colorSuccess;
      }

      &.save_disabled {
        fill: $colorGray;
        stroke: $colorGray;
      }
    }
  }
}

div.error {
  display: flex;
  justify-content: flex-end;
  margin-right: 3rem;
  color: $colorRed !important;
  font-weight: $fontWeightMedium;
}

.input {
  input {
    width: 100%;
  }
}
