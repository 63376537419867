@import 'constants/styles.constants.module';

.dropzoneWrapper {
  max-width: 50rem;
  margin: 0.5rem 0 2rem;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
  font-size: $fontSizeBiggerText;
  border: 0.2rem dashed $colorSecondary;
  border-radius: 0.5rem;
}

.deletePhotoButton {
  position: absolute;
  right: 0;
  z-index: 1;
  margin: 0.5rem;
}

.imgWrapper {
  display: flex;
  max-width: 25rem;
  height: 25rem;
  margin: 2rem 0.7rem;

  img {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  width: 100%;

  .size70 {
    width: calc(70%) !important;
  }

  .size30 {
    width: calc(30%) !important;
  }

  .cropperWrapper {
    width: 100%;
    margin-bottom: 2rem;
    outline: none;

    .imageWrapper {
      position: relative;
      max-width: 50rem;
      height: 25rem;
      margin-bottom: 2rem;
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;

    .image {
      position: relative;
      height: 25rem;
    }

    .deleteSelectedPhotoButton {
      margin: 0.7rem;
      font-size: $fontSizeBiggerText;
      cursor: pointer;
      /* stylelint-disable max-nesting-depth */
      &:hover {
        color: $colorSecondary;
      }
    }
  }
}
