@import 'constants/styles.constants.module';

div.root {
  position: relative;
  width: 100%;
  margin-top: 0 !important;
  margin-bottom: 1rem;
  padding-top: 3rem;
  overflow-x: auto;
  background-color: transparent;
  box-shadow: none;
  @include ie-only() {
    overflow: hidden;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

div.withTitle {
  margin-top: 1.687rem !important;
}

.tableHeader {
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 2.812rem;
}

div.actions {
  z-index: 4;
  display: flex;
  align-items: center;
  margin-right: 1rem;

  > a,
  > div {
    position: unset;
    top: unset;
    right: unset;
    margin-left: 1rem;
  }
}

.cardWrapper {
  position: relative;
}

.noData {
  position: relative;
  width: 100%;
  min-height: 9.4rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;
  background-color: #fff;
  border-radius: 0.5rem;

  h3 {
    display: block;
    font-weight: $fontWeightMedium;
    font-size: $fontSizeTitle2;
    text-align: center;
  }

  div {
    font-weight: $fontWeightLight;
    font-size: $fontSizeBiggestText;
    text-align: center;
  }
}

.tableWrapper {
  width: 100%;
}

.table {
  position: relative;
  width: 100%;
  text-align: left;
  background-color: $colorWhite;
  border: unset;
  border-radius: 0.5rem;
  border-collapse: collapse;
  box-shadow: $boxShadowTable;

  &.isLoading::after {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $colorWhite;
    border-radius: 0.5rem;
    content: '';
  }
  /* stylelint-disable selector-max-compound-selectors */
  thead > tr {
    th:first-child:not(.noLeftPadding) {
      padding-left: 2rem;
    }

    th:last-child {
      padding-right: 2rem;
    }
  }

  tbody:not(.noLeftPadding) > tr {
    td:first-child:not(.noLeftPadding) {
      padding-left: 2rem;
    }
  }

  tbody > tr {
    td:last-child {
      padding-right: 2rem;
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    z-index: 1;
    margin-top: 1rem;
  }

  span {
    z-index: 1;
  }
}

.loadingSmall {
  div {
    margin-bottom: 1rem;
  }
}

.headRow {
  height: 3.5rem;
  line-height: 3rem;
  border-bottom: 1px solid $colorBorder;
}

.headCol {
  padding: 1rem 0.4rem 0.5rem;
  color: $colorTextSecondary;
  font-weight: $fontWeightMedium;
  line-height: 1.35rem;
  border: unset;
  @include ie-only() {
    text-align: left;
  }
}

.foundRows {
  font-weight: $fontWeightLight;
  font-size: $fontSizeTitle2;
}

tr.row_slim {
  height: 2rem;
}

.bodyRow {
  height: 4rem;
  border-bottom: 1px solid $colorBorder;
  transition: 0.25s;

  &:last-child {
    border-bottom: unset;

    td:first-child {
      border-bottom-left-radius: 0.5rem;
    }

    td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
  }

  &:nth-child(even) {
    background-color: $colorBackgroundAlpha30;
  }

  &:hover {
    background-color: var(--colorBackgroundHighlightAlpha30);
  }
}

.bodyCol {
  padding: 0.5rem 0.4rem;
  font-weight: $fontWeightLight;
  border: unset;
}

.noContent {
  height: 5rem;
  text-align: center;
}

.lightFirst {
  div:first-child {
    font-weight: $fontWeightLight;
  }
}

.bold {
  font-weight: $fontWeightSemiBold;
}

.boldLast {
  div:last-child {
    font-weight: $fontWeightSemiBold;
  }
}

.green {
  color: $colorSuccess;
  font-weight: $fontWeightSemiBold;
}

.greenLast {
  div:last-child {
    color: $colorSuccess;
    font-weight: $fontWeightSemiBold;
  }
}

.greenFirst {
  div:first-child {
    color: $colorSuccess;
    font-weight: $fontWeightSemiBold;
  }
}

.gray {
  color: $colorGray;
  font-weight: $fontWeightSemiBold;
}

.grayFirst {
  div:first-child {
    color: $colorGray;
    font-weight: $fontWeightSemiBold;
  }
}

.grayLast {
  div:last-child {
    color: $colorGray;
    font-weight: $fontWeightSemiBold;
  }
}

.redFirst {
  div:first-child {
    color: $colorRed;
    font-weight: $fontWeightSemiBold;
  }
}

%circlejoined {
  span {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    color: $colorWhite;
    font-weight: $fontWeightMedium;
    background-color: $colorMain;
    border-radius: 1rem;
  }
}

.darkCircle {
  @extend %circlejoined;
}

.greenCircle {
  @extend %circlejoined;

  span {
    background-color: $colorGreen;
  }
}

%iconcircle {
  width: 1rem !important;
  height: 1rem !important;
  color: $colorWhite;
  border-radius: 2rem;
  stroke: $colorWhite;
}

svg.greenCircle {
  @extend %iconcircle;
  background-color: $colorGreen;
}

svg.redCircle {
  @extend %iconcircle;
  background-color: $colorRed;
}

svg.yellowCircle {
  @extend %iconcircle;
  background-color: $colorYellow;
}

.boldFirst {
  div:first-child {
    font-weight: $fontWeightSemiBold;
  }
}

tr.highlight {
  border-bottom: 2px dashed $colorGray;
}

span.valueSimple {
  width: auto;
  height: 1.1rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  padding-right: 0.125rem;
  padding-left: 0.125rem;
}

.centerText {
  text-align: center;
}

.iconWrapper {
  display: flex;
  align-items: center;

  &.iconCenter {
    justify-content: center;
  }
}

a.icon svg {
  height: 1.1rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  padding-right: 0.125rem;
  padding-left: 0.125rem;
}

svg.icon {
  height: 1.1rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  padding-right: 0.125rem;
  padding-left: 0.125rem;
}

svg.iconOrange * {
  stroke: $colorOrange;
}

svg.iconGray * {
  stroke: $colorGray;
}

.cursorPointer {
  cursor: pointer;
}

.cursorGrab {
  cursor: grab;
}

svg.angleRight {
  position: relative;
  top: -0.1rem;
  width: 1rem;
  height: 0.4rem;
  margin-left: 1rem;
  transform: rotate(-90deg);
  stroke: $colorMain;
}

svg.iconHead {
  width: 1rem;
  height: 1rem;
}

svg.stringOnClickIcon {
  width: 1rem;
  height: 1rem;
  stroke-width: 2;
}

svg.burgerIcon {
  width: 1rem;
  height: 1rem;
  fill: $colorGray;
}

tr.table-row-dragged {
  background-color: $colorWhite !important;

  td:first-child:not(.noLeftPadding) {
    padding-left: 2rem;
  }

  td:last-child {
    padding-right: 2rem;
  }
}

a.iconDetail {
  padding: 0.5rem 0.2rem;

  &:hover {
    svg {
      fill: var(--colorSecondary);
      stroke: var(--colorSecondary);
    }
  }

  svg {
    height: 1.1rem;
    fill: var(--colorMain);
    stroke: var(--colorMain);

    @include ie-only() {
      width: 1.1rem;
    }
  }
}

svg.iconDetail {
  height: 1.1rem;
  fill: var(--colorMain);
  stroke: var(--colorMain);

  @include ie-only() {
    width: 1.1rem;
  }

  &:hover {
    fill: var(--colorSecondary);
    stroke: var(--colorSecondary);
  }
}

.onChooseLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.onChooseBtn {
  color: var(--colorSecondary);
  font-weight: $fontWeightMedium;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: relative;
      width: 1rem;
      height: 0.4rem;
      margin-left: 1rem;
      transform: rotate(-90deg);
      fill: var(--colorSecondary);
      stroke: var(--colorSecondary);
    }
  }

  &:hover {
    color: var(--colorSecondaryHover);

    span svg {
      fill: var(--colorSecondaryHover);
      stroke: var(--colorSecondaryHover);
    }
  }
}

.colSize-S {
  max-width: 4rem;
}

.colSize-M {
  max-width: 8rem;
}

.colSize-L {
  max-width: 16rem;
}

.colSize-XS-fixed {
  width: 2rem;
}

.colSize-S-fixed {
  width: 4rem;
}

.colSize-SM-fixed {
  width: 6rem;
}

.colSize-M-fixed {
  width: 12rem;
}

.colSize-SML {
  width: 10rem;
}

.colSize-SML-fixed {
  max-width: 10rem;
}

.colSize-ML-fixed {
  width: 14rem;
}

.colSize-L-fixed {
  width: 16rem;
}

.colSize-XL-fixed {
  width: 20rem;
}

.colSize-UNSET {
  max-width: unset;
}

.colColor-GREEN {
  color: $colorGreen;
}

.colColor-BLUE {
  color: $colorSecondary;
}

.colColor-ORANGE {
  color: $colorOrange;
}

.colColor-GRAY {
  color: $colorGray;
}

.colColor-RED {
  color: $colorRed;
}

.colColor-ORANGE_SECOND {
  > :nth-child(2) {
    color: $colorOrange;
  }
}

.colColor-RED_SECOND {
  > :nth-child(2) {
    color: $colorRed;
  }
}

.colColor-GREEN_SECOND {
  > :nth-child(2) {
    color: $colorGreen;
  }
}

.colColor-RED_BLUE {
  > :first-child {
    color: $colorRed;
  }

  > :nth-child(2) {
    color: $colorSecondary;
  }
}

.buttonWrap {
  display: flex;
  justify-content: center;
}

.block {
  display: block;
  margin-bottom: 0.2rem;
}

button.button {
  margin-top: 2rem;
}

.showMore {
  text-align: center;
}

.tableRoundInput {
  height: 100%;
  border: 0.1rem solid $colorBorder;
  border-radius: 1rem;
  box-shadow: none;

  input {
    width: 100%;
    height: 2rem;
    font-size: $fontSizeText;
    border: unset;
    border-radius: 1rem;
    outline: none;
  }

  input[type='text'] {
    padding-left: 0.5rem;
    text-align: left;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
}

.fieldRequired {
  border: 1px solid #fb7d7d;
  outline: none;
}

.modalView {
  padding-top: 1rem;
  border-color: $colorBorder;

  .tableWrapper {
    border: 0.1rem solid $colorBorder;
    border-radius: 0.5rem;
  }
}

.inlineEdit {
  position: relative;
  cursor: pointer;

  span {
    position: relative;
  }

  .inlineEditIcon {
    width: 0.8rem;
    height: 0.8rem;
    margin-left: 5px;
    visibility: hidden;
    stroke: $colorSecondaryHover;
    stroke-width: 1px;
  }

  &:hover {
    .inlineEditIcon {
      visibility: visible;
    }
  }
}

.contextWithIcon {
  display: inline-flex;
  align-items: center;
}

.contextLabel {
  font-weight: $fontWeightMedium;
}

.contextMenu {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.noFullWidth {
  width: unset;
}

.inline {
  display: inline-block;

  div {
    display: inline-block;
  }
}

.disabled {
  color: $colorGray;
  cursor: default;
}

.iconDisabled {
  fill: $colorGray !important;
  stroke: $colorGray !important;
}

.smallerPadding {
  padding-top: 1rem !important;
}

.secondMoreAdditional {
  div:first-child {
    padding-bottom: 0.3rem;
  }

  div:nth-child(n + 2) {
    font-size: $fontSizeSmallestText;
  }
}

.booleanInnerContent {
  margin-left: 5px;
  text-align: left;
}

.scrollMarginTop {
  scroll-margin-top: 1rem;
}

.link {
  position: relative;
  display: inline-block;
  align-items: center;
  padding-right: 1rem;
  color: var(--colorSecondary);
  font-weight: $fontWeightSemiBold;
  font-size: $fontSizeSmallText;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--colorLinkHover);

    &::after {
      border-color: var(--colorLinkHover) !important;
    }
  }
}

.noIcon {
  display: inline-block;
}

.link:not([class*='noIcon'])::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  margin-top: -0.31rem;
  margin-left: 0.25rem;
  vertical-align: center;
  border-top: 2px solid var(--colorSecondary);
  border-right: 2px solid var(--colorSecondary);
  transform: rotate(45deg);
  content: ' ';
}

.mainTitle {
  margin-bottom: -0.8rem;
}

.actionButtonsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  button:first-of-type {
    margin-right: 1rem;
  }
}
