@import 'constants/styles.constants.module';

.wrapper {
  padding-top: 2rem;
  text-align: center;

  a {
    margin-top: 1rem;
  }
}

.loading {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
}
