@import 'constants/styles.constants.module';

.dropzone {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  color: $colorGray;
  border: 2px dashed $colorBorder;
  border-radius: 0.5rem;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.fileWrapper {
  display: flex;
}

.fileName {
  flex: 1;
  word-break: break-word;
}

.deleteLink {
  flex-basis: 3rem;
  padding-left: 0.7rem;
  color: $colorSecondary;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--colorLinkHover);
  }
}

div.error {
  color: $colorDarkHighlight !important;
}
