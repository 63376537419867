@import 'constants/styles.constants.module';

.filterGroup {
  display: flex;
  flex-direction: column;

  @include ie-only() {
    input[type='date'] + div {
      position: absolute !important;
      top: 40%;
      z-index: 5 !important;
    }
  }

  div[accesskey='labelWrapper'] {
    @include ie-only() {
      flex-basis: inherit;
    }
  }

  input,
  select,
  textarea,
  div[data-div-input='true'] {
    border: 1px solid #26336055;
    border-radius: 0.5rem;
    color: $colorMain;
    font-weight: $fontWeightLight;
    margin-top: 0.5rem;
    @include ie-only() {
      border: 1px solid rgba(38, 51, 96, 0.33);
    }
  }
}

.noFlexDirection {
  flex-direction: unset !important;
}

.textCenter {
  text-align: center;
}

.textCenter {
  text-align: center;
}

.center {
  justify-content: center;
}

div.noShadow {
  box-shadow: unset;
}

.changeInput {
  input,
  select,
  div[data-div-input='true'] {
    color: var(--colorSecondary);
    font-weight: $fontWeightNormal;
  }
}

.onePerRow {
  width: 100%;
}

.fillRow {
  flex-grow: 1;
}

.twoPerRow {
  width: 50%;
}

.threePerRow {
  width: 33.333%;
}

.fourPerRow {
  width: 25%;
}

.threeQuarters {
  width: 75%;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.section {
  margin-bottom: 2rem;
}

.sectionTitle {
  font-size: $fontSizeTitle2;
  font-weight: $fontWeightLight;
}

.buttonWrapper {
  display: flex;
  margin-top: 1rem;
  padding-bottom: 2rem;

  @include max(xs) {
    justify-content: center;
  }

  button {
    margin-right: 1.4rem;
    @include ie-only() {
      position: static;

      /* stylelint-disable max-nesting-depth */
      span {
        left: inherit;
        margin-left: -2.3rem;
        margin-top: 3rem;
        top: inherit;
        width: 15%;
      }
    }
  }
}

.maxWidth-60 {
  max-width: 60rem;
}

.maxWidth-FULL {
  max-width: 100%;
}

.reasonInfo {
  background-color: $backgroundToastOrange;
  border-radius: 0.5rem;
  color: $colorWhite;
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;

  > div {
    font-weight: 800;
  }

  &.success {
    background-color: $backgroundToastGreen;
  }

  &.danger {
    background-color: $backgroundToastRed;
  }

  &.warning {
    background-color: $backgroundToastOrange;
  }

  &.info {
    background-color: $colorMain;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
  width: 0;
}

.error {
  color: $colorDarkHighlight !important;
  display: flex;
  font-size: $fontSizeBiggerText;
  padding-left: 2rem;
}

.globalError {
  color: $colorDarkHighlight;
  display: flex;
  font-size: $fontSizeSmallestText;
  padding-left: 2rem;
}

svg.iconPlus {
  background-color: $colorGreen;
  border-radius: 50%;
  height: 1.25rem;
  margin-right: 0.5rem;
  padding: 0.25rem;
  stroke: $colorWhite;
}

.specialAddButton {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: $fontWeightMedium;
  height: 4rem;

  &.disabled {
    cursor: default;

    svg {
      background-color: $colorGray;
    }
  }
}

.specialButton {
  height: 2rem;
}

.bigger {
  height: 4rem;
}

.specialButtonWrap {
  align-items: flex-end;
  display: flex;
}

div.note {
  background: #fff;
  border: 1px solid $backgroundToastOrange;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.6rem;
  position: relative;

  &.dangerNote {
    border: 1px solid $colorWarning;

    &::before {
      border-right: 1px solid $colorWarning;
      border-top: 1px solid $colorWarning;
    }
  }

  &::before {
    background: #fff;
    border-right: 1px solid $backgroundToastOrange;
    border-top: 1px solid $backgroundToastOrange;
    content: ' ';
    display: block;
    height: 0.8rem;
    left: 1rem;
    position: absolute;
    top: -0.45rem;
    transform: rotate(315deg);
    width: 0.8rem;
  }

  &::after {
    background: #fff;
    content: ' ';
    display: block;
    height: 2px;
    left: 0.5rem;
    position: absolute;
    top: 0;
    width: 2rem;
  }
}

.hidden {
  display: none;
}

.test {
  display: contents;
}
