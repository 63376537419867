@import 'constants/styles.constants.module';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2.5rem;
}

.page {
  padding: 0 0.5rem;
  color: $colorTextSecondary;
  font-weight: $fontWeightLight;
  font-size: $fontSizeBiggerText;
  line-height: 2.5rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: var(--colorSecondaryHover);
    font-weight: $fontWeightMedium;
  }
}

.page::after {
  @extend %activeshared;
  display: block;
  height: 1px;
  overflow: hidden;
  color: transparent;
  visibility: hidden;
  content: attr(title);
}

.icon {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 2.5rem;
  margin-left: 0.3rem;
  cursor: pointer;

  &:hover {
    &::after {
      border-color: var(--colorSecondary);
    }
  }

  &::after {
    position: absolute;
    top: calc(50% - 0.39rem);
    display: block;
    width: 0.45rem;
    height: 0.45rem;
    border-top: 2px solid $colorTextSecondary;
    border-right: 2px solid $colorTextSecondary;
    transform: rotate(45deg);
    content: '';
  }
}

.iconPrev {
  margin-right: 0.3rem;
  margin-left: 0;

  &::after {
    transform: rotate(-135deg);
  }
}

%activeshared {
  color: var(--colorMain);
  font-weight: $fontWeightMedium;
}

.active {
  @extend %activeshared;
}
