@import 'constants/styles.constants.module';

.root {
  background-color: $colorSecondary;

  border: 1px solid $colorMain;
  border-radius: 1rem;
  color: $colorWhite;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
  word-spacing: 1rem;
}
