@import 'constants/styles.constants.module';

.marginTop2 {
  margin-top: 2rem;
}

.filterGroup {
  display: flex;
  @include max(sm) {
    width: 100%;
  }
}

.break {
  flex-basis: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
}

.filterGroupRow {
  display: flex;
}

.firstRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.searchBar {
  flex: 1;
}

.toggleFilter {
  align-self: center;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  &:hover {
    color: var(--colorSecondary);
  }
}

.toggleIcon {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.25rem;
  vertical-align: center;
  border-top: 2px solid var(--colorSecondary);
  border-right: 2px solid var(--colorSecondary);
  transform: rotate(-45deg);

  &.open {
    margin-bottom: 0.25rem;
    transform: rotate(135deg);
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.hidden {
  display: none;
}

.marignLeft {
  margin-left: 1rem;
}

.filterActions {
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
  margin-top: 0.5rem;
}

.bottomButtons {
  display: flex;
  @include max(sm) {
    width: 100%;
  }

  button {
    min-width: 6rem;
    height: 2rem;
    border-radius: 1.5rem;

    @include max(sm) {
      width: 100%;
    }
  }
}

.onePerRow {
  width: 100%;
}

.twoPerRow {
  width: 50%;
}

.threePerRow {
  width: 33.333%;
}

.fourPerRow {
  width: 25%;
}
