@import 'constants/styles.constants.module';

.wrapper {
  display: flex;
}

.flexItem {
  flex-basis: 50%;

  &:nth-child(2) {
    padding-top: 4rem;
    padding-left: 1rem;
  }
}
