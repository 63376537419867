@import 'constants/styles.constants.module';

.root {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

div.inputWrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  padding-right: 2rem;
  padding-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  background: $colorWhite;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 13px);
  background-position-y: 0.8rem;
  background-size: 0.6rem;

  @include ie-only() {
    padding-right: 0;
    border-right: 2rem;
  }

  .input {
    width: 100%;
    overflow: hidden;
    outline: none;

    &:focus {
      text-overflow: clip;
    }

    &:not(:focus) {
      text-overflow: ellipsis;
    }

    &[data-placeholder]:not([data-placeholder='']):empty::before {
      content: attr(data-placeholder);
    }

    &::before {
      color: #929292;
      font-weight: $fontWeightLight;
    }
  }
}

div.valueChanged {
  color: $colorSecondary !important;
  font-weight: $fontWeightNormal !important;
}

.iconWrap {
  position: absolute;
  top: calc(50% - 0.35rem);
  right: 1rem;
  z-index: 7;
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
}

.iconBiggerWrap {
  top: calc(50%) !important;
}

.pointer {
  cursor: pointer;
}

svg.cancelIcon {
  stroke: $colorMain;

  &:hover * {
    stroke: $colorSecondary;
  }
}

.dropdown {
  position: absolute;
  top: 2rem;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 20rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  font-weight: $fontWeightLight;
  font-size: $fontSizeText;
  background-color: $colorWhite;
  border: unset;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  box-shadow: $boxShadowInput;

  @include ie-only() {
    box-shadow: $boxShadowInputIE;
  }

  div > div {
    outline: none;
  }
}

.options {
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .option {
    padding: 0.25rem 1rem;
    color: $colorBlack;
    line-height: 1.5rem;
    cursor: default;

    &:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
    }

    &:hover,
    &:focus {
      color: $colorWhite;
      background-color: var(--colorSecondary);
      outline: none;
    }
  }

  .active {
    color: $colorWhite;
    background-color: var(--colorSecondary);
    outline: none;
  }
}

.biggerDropdown {
  top: 3.5rem;
  z-index: 6;
  border: 1px solid $colorMainAlpha55;
  border-top: unset;
}

.noResult {
  padding: 0.25rem 1rem;
  line-height: 1.5rem;
  cursor: default;
}

.disabled {
  background-color: $colorInputDisabled !important;
  pointer-events: none;
}

.optionDisabled {
  color: #c5c5c5 !important;
  background-color: #a09f9f17 !important;
  cursor: default;
  pointer-events: none;
}

.error {
  position: absolute;
  top: 105%;
  right: 0.3rem;
  color: $colorDarkHighlight !important;
  font-size: $fontSizeSmallestText;
}
